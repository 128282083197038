<template>
	<div class="page">
		<Head :headTitle="headTitle" />
		<div class="b-container">
			<div class="con-l">
				<div class="mod1 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">产品溯源</span>
					</div>
					<div class="b-smtit">
						<span class="bt">农产品概况</span>
					</div>
					<ul class="mod1-gk">
						<li>
							<img src="../assets/images/sy_01.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">年产品种类数</h6>
								<p class="num" style="color:#489FFE;">935种</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_02.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">年产品总数量</h6>
								<p class="num" style="color:#85B1FF;">9,350,000吨</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_03.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">月可供应产品数量</h6>
								<p class="num" style="color:#90FFAD;">779,166吨</p>
							</div>
						</li>
					</ul>
					<div class="b-smtit">
						<span class="bt">主要产品分布地图</span>
						
					</div>
					<ul class="mod1-fb">
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_04.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">油茶</h6>
								<div class="txt">平等镇、泗水乡</div>
								<div class="mount">
									<span style="color:#FFC55A">11.2</span>万吨
								</div>
							</div>
						</li>
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_05.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">茶叶</h6>
								<div class="txt">龙脊镇、平等镇</div>
								<div class="mount">
									<span style="color:#90FFAD">13.5</span>万吨
								</div>
							</div>
						</li>
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_06.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">罗汉果</h6>
								<div class="txt">瓢里镇、泗水乡</div>
								<div class="mount">
									<span style="color:#14C1FD">349.6</span>万吨
								</div>
							</div>
						</li>
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_07.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">百香果</h6>
								<div class="txt">龙脊镇、瓢里镇</div>
								<div class="mount">
									<span style="color:#85B1FF">372.6</span>万吨
								</div>
							</div>
						</li>
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_08.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">凤鸡</h6>
								<div class="txt">三门镇、马堤乡</div>
								<div class="mount">
									<span style="color:#FF744B">32.5</span>万吨
								</div>
							</div>
						</li>
						<li class="item">
							<div class="imgbox">
								<img src="../assets/images/sy_09.png" class="icon" alt="">
							</div>
							<div class="info">
								<h6 class="tit">翠鸭</h6>
								<div class="txt">江底乡、乐田镇、乐江镇</div>
								<div class="mount">
									<span style="color:#FFC55A">12.8</span>万吨
								</div>
							</div>
						</li>
					</ul>
					<div class="mod1-chart flex-11">
						<div class="col">
							<div class="b-smtit">
								<span class="bt">各产品种类占比图</span>
							</div>
							<div class="chart" ref="chart1"></div>
						</div>
						<div class="col">
							<div class="b-smtit">
								<span class="bt">主要产品过去一年供应趋势</span>
							</div>
							<div class="chart" ref="chart2"></div>
						</div>
					</div>
					<div class="mod1-di">
						<div class="col" style="width:44%;">
							<div class="b-smtit">
								<span class="bt">主要产品产出溯源表</span>
							</div>
							<div class="scroll-list flex flex-v">
								<div class="row-t">
									<div style="width:35%;">农户</div>
									<div style="width:20%;">产品名称</div>
									<div style="width:30%;">产出数量</div>
									<div style="width:15%;">月份</div>
								</div>
								<div class="scrollbb">
									<vue-seamless-scroll :data="suyuanList" :class-option="scrollOption" class="scrollb">
										<div class="scrollbox flex-1">
											<div class="row" v-for="(item,index) in suyuanList" :key="index">
												<div style="width:35%;">{{item.title}}</div>
												<div style="width:20%;">{{item.name}}</div>
												<div style="width:30%;">{{item.output}}</div>
												<div style="width:15%;">{{item.date}}</div>
											</div>
										</div>
									</vue-seamless-scroll>
									<span class="linebg"></span>
								</div>
							</div>
						</div>
						<div class="col" style="width:52%;">
							<div class="b-smtit">
								<span class="bt">原材料产地实时场景</span>
							</div>
							<ul class="list">
								<li class="item" v-for="(item,index) in chandiList">
									<div class="imgbox">
										<img :src="item.img" class="img img_full" alt="">
									</div>
									<div class="info">
										<h6 class="title">{{item.title}}</h6>
										<div class="bot">
											<span class="txt">{{item.txt}}</span>
											<span class="tag">{{item.tag}}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="con-c flex flex-v relative">
				<div class="mod-cen mod2">
					<div class="cen-menu">
						<span class="lk">智慧城市</span>
						<span class="lk">数字乡村振兴</span>
						<span class="lk cur">智慧溯源</span>
						<span class="lk">智慧文旅</span>
					</div>
					<div class="cen-top">
						<li>
							<h6 class="tit">全年工业产值 <em>(万元)</em></h6>
							<p class="num">221,700</p>
						</li>
						<li class="line"></li>
						<li>
							<h6 class="tit">规模工业产值 <em>(万元)</em></h6>
							<p class="num">166,300</p>
						</li>
						<li class="line"></li>
						<li>
							<h6 class="tit">建筑业产值 <em>(万元)</em></h6>
							<p class="num">80,100</p>
						</li>
					</div>
					<div class="mod2-map">
						<div class="item item1">
							<div class="b-smtit">
								<span class="bt">在途产品数</span>
							</div>
							<div class="txt">
								<p>
									<span class="tit">本月已发产品数</span>
									<em>165,375吨</em>
								</p>
								<p>
									<span class="tit">本月待发产品数</span>
									<em>264,600吨</em>
								</p>
							</div>
						</div>
						<div class="item item2">
							<div class="b-smtit">
								<span class="bt">加工工厂</span>
							</div>
							<div class="txt">
								<p>
									<span class="tit">加工工厂数量</span>
									<em>330,75种</em>
								</p>
								<p>
									<span class="tit">加工产品种类</span>
									<em>896种</em>
								</p>
								<p>
									<span class="tit">加工产品种类</span>
									<em>896吨</em>
								</p>
							</div>
						</div>
						<div class="item item3">
							<div class="b-smtit">
								<span class="bt">产业园</span>
							</div>
							<div class="txt">
								<p>
									<span class="tit">产业园数量</span>
									<em>26间</em>
								</p>
								<p>
									<span class="tit">产业园产品种类</span>
									<em>2,689种</em>
								</p>
								<p>
									<span class="tit">产品数量</span>
									<em>6,828吨</em>
								</p>
							</div>
						</div>
					</div>
					<div class="mod2-bot">
						<div class="col col1">
							<div class="b-smtit">
								<span class="bt">运输方式</span>
							</div>
							<div class="chart" ref="chart3"></div>
						</div>
						<div class="col col2 flex flex-v">
							<div class="b-smtit">
								<span class="bt">工厂列表</span>
							</div>
							<div class="scroll-list flex-1 flex flex-v">
								<div class="row-t">
									<div style="width:43%;">工厂名称</div>
									<div style="width:35%;">加工产品种类</div>
									<div style="width:22%;">产品数量</div>
								</div>
								<div class="scrollbb">
									<vue-seamless-scroll :data="factoryList" :class-option="scrollOption" class="scrollb">
										<div class="scrollbox flex-1">
											<div class="row" v-for="(item,index) in factoryList" :key="index">
												<div style="width:43%;">{{item.title}}</div>
												
												<div style="width:35%;">{{item.cata}}种</div>
												<div style="width:22%;">{{item.amount}}吨</div>
											</div>
										</div>
									</vue-seamless-scroll>
									<span class="linebg"></span>
								</div>
							</div>
						</div>
						<div class="col col3 flex flex-v">
							<div class="b-smtit">
								<span class="bt">产业园区</span>
							</div>
							<div class="scroll-list flex-1 flex flex-v">
								<div class="row-t">
									<div style="width:48%;">园区名称</div>
									<div style="width:30%;">产品种类</div>
									<div style="width:22%;">产品数量</div>
								</div>
								<div class="scrollbb">
									<vue-seamless-scroll :data="yuanquList" :class-option="scrollOption" class="scrollb">
										<div class="scrollbox flex-1">
											<div class="row" v-for="(item,index) in yuanquList" :key="index">
												<div style="width:48%;">{{item.title}}</div>
												
												<div style="width:30%;">{{item.cata}}种</div>
												<div style="width:22%;">{{item.amount}}吨</div>
											</div>
										</div>
									</vue-seamless-scroll>
									<span class="linebg"></span>
								</div>
								
							</div>
						</div>
					</div>
				</div>
					
				
			</div>
			<div class="con-r flex flex-v">
				<div class="mod3 flex flex-v">
					<div class="b-pub-tt flex">
						<span class="bt">仓储消费溯源</span>
					</div>
					<div class="b-smtit">
						<span class="bt">消费概况</span>
					</div>
					<ul class="mod1-gk mod3-gk">
						<li>
							<img src="../assets/images/sy_15.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">仓库数量</h6>
								<p class="num" style="color:#489FFE;">280个</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_16.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">存储产品种类数</h6>
								<p class="num" style="color:#85B1FF;">831种</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_17.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">产品数量</h6>
								<p class="num" style="color:#90FFAD;">3286吨</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_18.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">消费覆盖省份数</h6>
								<p class="num" style="color:#FFC55A;">21个</p>
							</div>
						</li>
						<li>
							<img src="../assets/images/sy_19.png" class="icon" alt="">
							<div class="info">
								<h6 class="tit">城市数量</h6>
								<p class="num" style="color:#45F5FF;">89个</p>
							</div>
						</li>
					</ul>
					<div class="mod3-sl">
						<div class="col col1">
							<div class="b-smtit">
								<span class="bt">仓库数量</span>
							</div>
							<div class="hang">
								普通仓库数量 <span>190个</span>
							</div>
							<div class="hang">
								恒温仓库数量 <span>70个</span>
							</div>
							<div class="hang">
								冷藏仓库数量 <span>20个</span>
							</div>
						</div>
						<div class="col col2">
							<div class="b-smtit">
								<span class="bt">产品储存数量对应仓库排名</span>
							</div>
							<ul class="bar-list">
								<li class="row" v-for="(item,index) in chucunshuliangList" :key="index">
									<span class="num">{{index + 1}}</span>
									<div class="smbox">
										<div class="font">
											<span>{{item.name}}</span>
											<span>{{item.num}}吨</span>
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</li>
							</ul>
							
						</div>
						<div class="col col3">
							<div class="b-smtit">
								<span class="bt">储存产品数量</span>
							</div>
							<div class="chart" ref="chart4"></div>
							
						</div>
					</div>
					<div class="mod3-pm">
						<div class="b-smtit">
							<span class="bt">消费城市前五名</span>
						</div>
						<ul class="list">
							<li class="item" v-for="(item,index) in pmList" :key="index">
								<div class="imgbox">
									<img :src="item.img" class="img img_full" alt="">
								</div>
								<div class="box">
									<div class="flex flex-pack-start">
										<span class="sz">{{index + 1}}</span>
										<div class="info">
											<h6 class="city">{{item.city}}</h6>
											<div class="txt">{{item.txt}}</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="mod3-di flex-1">
						<div class="col col1 flex flex-v">
							<div class="b-smtit">
								<span class="bt">产品消费订单列表</span>
							</div>
							<div class="scroll-list flex-1 flex flex-v">
								<div class="row-t">
									<div style="width:20%;">产品编号</div>
									<div style="width:20%;">产品名称</div>
									<div style="width:20%;">产品数量</div>
									<div style="width:20%;">销往城市</div>
									<div style="width:20%;">日期</div>
								</div>
								<div class="scrollbb">
									<vue-seamless-scroll :data="xiaofeidingdanList" :class-option="scrollOption" class="scrollb">
										<div class="scrollbox flex-1">
											<div class="row" v-for="(item,index) in xiaofeidingdanList" :key="index">
												<div style="width:20%;">{{item.number}}</div>
												<div style="width:20%;">{{item.name}}</div>
												<div style="width:20%;">{{item.output}}</div>
												<div style="width:20%;">{{item.city}}</div>
												<div style="width:20%;">{{item.date}}</div>
											</div>
										</div>
									</vue-seamless-scroll>
									<span class="linebg"></span>
								</div>
							</div>
						</div>
						<div class="col col2">
							<div class="b-smtit">
								<span class="bt">消费产品种类TOP5</span>
							</div>
							<ul class="bar-list">
								<li class="row" v-for="(item,index) in topList" :key="index">
									<span class="num">{{index + 1}}</span>
									<div class="smbox">
										<div class="font">
											<div class="flex">
												<span>{{item.number}}</span>
												<span>{{item.title}}</span>
											</div>
											<span>销售额：{{item.volume}}万元</span>
										</div>
										<div class="progress"><i :style="{'width': item.widthVar}"></i></div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					
					
					
					
					
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/head_big.vue'
	let Echarts = require('echarts/lib/echarts');
	import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
	export default {
		data() {
			return {
				headTitle: '龙胜县智慧溯源展示中心',
				chandiList:[{
					img:require('../assets/images/sy_11.gif'),
					title:'A地',
					txt:'农田玉米',
					tag:'正常'
				},{
					img:require('../assets/images/sy_12.gif'),
					title:'B地',
					txt:'试验大鹏',
					tag:'正常'
				},{
					img:require('../assets/images/sy_13.gif'),
					title:'C地',
					txt:'蚕豆',
					tag:'正常'
				},{
					img:require('../assets/images/sy_14.gif'),
					title:'D地',
					txt:'农业大棚',
					tag:'正常'
				}],
				
				chucunshuliangList:[{
					name:'中心仓',
					num:'42,310',
					widthVar:'95%'
				},{
					name:'农产品仓储冷链',
					num:'2,115.5',
					widthVar:'80%'
				},{
					name:'侗山耕冷链',
					num:'423.1',
					widthVar:'65%'
				}],
				pmList:[{
					img:require('../assets/images/sy_20.jpeg'),
					city:'深圳',
					txt:'33.08'
				},{
					img:require('../assets/images/sy_21.jpeg'),
					city:'广州',
					txt:'26.46'
				},{
					img:require('../assets/images/sy_22.jpeg'),
					city:'南宁',
					txt:'19.85'
				},{
					img:require('../assets/images/sy_20.jpeg'),
					city:'襄阳',
					txt:'16.54'
				},{
					img:require('../assets/images/sy_21.jpeg'),
					city:'福州',
					txt:'9.92'
				}],
				topList:[{
					number:'No.00789',
					title:'榕江脐橙',
					volume:'239.2',
					widthVar:'85%'
				},{
					number:'No.00445',
					title:'榕江小香鸡',
					volume:'222.8',
					widthVar:'70%'
				},{
					number:'No.00291',
					title:'黑毛猪',
					volume:'221.9',
					widthVar:'60%'
				},{
					number:'No.00002',
					title:'锡利贡米',
					volume:'188.2',
					widthVar:'50%'
				},{
					number:'No.00662',
					title:'塔石香羊',
					volume:'168.6',
					widthVar:'45%'
				}],
				xiaofeidingdanList: [{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
					{
						number: "618873",
						name: "榕江脐橙",
						output: "8.25万吨",
						city: "广州",
						date:'2021.11.23'
					},
				],
				suyuanList:[{
					title: "农民专业合作社",
					name: "小麦",
					output: "253,8602",
					date:'11月'
				},{
					title: "豆帮合作社",
					name: "小麦",
					output: "253,8602",
					date:'11月'
				},{
					title: "农民专业合作社",
					name: "小麦",
					output: "253,8602",
					date:'11月'
				},{
					title: "农民专业合作社",
					name: "小麦",
					output: "253,8602",
					date:'11月'
				}],
				factoryList:[{
					title: "胜国不锈钢加工厂",
					cata: "606",
					amount:'56082'
				},{
					title: "农产品加工厂",
					cata: "606",
					amount:'56082'
				},{
					title: "胜国不锈钢加工厂",
					cata: "606",
					amount:'56082'
				}],
				yuanquList:[{
					title: "龙胜县工业园区",
					cata: "928",
					amount:'56082'
				},{
					title: "工业园区管理委员会",
					cata: "606",
					amount:'56082'
				},{
					title: "果树产业开发办公室",
					cata: "606",
					amount:'56082'
				}],
				
				
				chart1: null,
				chart2: null,
				tootipTimer2:"",
				chart4: null,
			}
		},
		components: {
			Head

		},
		computed: {
			scrollOption() {
				return {
					step: 0.3, // 数值越大速度滚动越快
					// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					// openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		created() {

		},
		mounted() {
			this.init1();
			this.init2();
			this.init3();
			this.init4();
			window.addEventListener('resize', () => {
				this.chart1.resize();
				this.chart2.resize();
				this.chart3.resize();
				this.chart4.resize();
			});
		},
		
		methods: {
			init1() {
				this.chart1 = Echarts.init(this.$refs.chart1);
				const chartData = [
					{
						value: 274,
						name: '蔬菜',
					},
					{
						value: 243,
						name: '豆类'
					},
					{
						value: 228,
						name: '薯类'
					},
					{
						value: 776,
						name: '其他农作物'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|种植面积}\n\n{b|' + sum + '万亩}',
						top: 'center',
						textStyle: {
							 rich: {
								a: {
								   fontSize: nowSize(20),
								   padding: [0, 0, 0,nowSize(93)],
								   color: '#fff',
								   lineHeight:1,
								    fontFamily:'YouSheBiaoTiHei',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(94)],
									color: '#45F5FF',
									lineHeight:1,
									fontFamily:'YouSheBiaoTiHei'
								}
							}
						}
				   },
					tooltip: {
						trigger: 'item',
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: '52%',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(16),
						itemHeight: nowSize(16),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18),
							lineHeight: nowSize(30)
						},
						itemGap:  nowSize(15),
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万亩}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   padding: [0, nowSize(15), 0, 0],
								   color: '#fff',
								},
								value: {
									fontSize: nowSize(18),
									height: nowSize(20),
									padding: [0, nowSize(15), 0, 0],
									color: '#45F5FF',
								},
								percent: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#03b581', '#3478ff', '#fc9d1f', '#fe4334'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '73%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#031232',
							borderWidth: nowSize(2)
						},
						labelLine: {
							show: false
						},
						data:chartData
					},{
						 type: 'pie',
						 name: '内层细圆环',
						radius: ['52%', '54%'],
						center: ['25%', '48%'],
						 hoverAnimation: false,
						 clockWise: true,
						 itemStyle: {
							normal: {
							   color: '#178ac9',
							},
						 },
						 tooltip: {
							show: false,
						 },
						 label: {
							show: false,
						 },
						 data: [1],
					}]
			
				};
				this.chart1.setOption(option, true);
				
				
				
				// this.tootipTimer4 && this.tootipTimer4.clearLoop();
				// this.tootipTimer4 = 0;
				// this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
			},
			
			init2() {
				this.chart2 = Echarts.init(this.$refs.chart2);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #FD9D20',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						}
					},
					toolbox: {
						show: true
					},
					grid: {
						left: nowSize(20),
						right: nowSize(40),
						bottom: nowSize(10),
						top: nowSize(20),
						containLabel: true
					},

					// legend: {
					// 	x: 'right',
					// 	y: 'top',
					// 	icon:'rect',
					// 	itemWidth:nowSize(14),
					// 	itemHeight:nowSize(14),
					// 	textStyle: {
					// 		color: '#fff',
					// 		fontSize: nowSize(18)
					// 	},
					// },
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(18)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 30000,
						interval: 50000,
						nameTextStyle: {
							color: "#fff",
							fontSize: nowSize(18),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(18), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'solid'
							}
						}
					}],
			
					series: [{
						name: '',
						type: 'line',
						data: [144240,166698,164383,187535,192166,189850,243101,209762,208604,238471,210688,159752],
						smooth: true,
						symbolSize: 1, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD9D20", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD9D20" //折线的颜色
			
								},
								areaStyle: {
									color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									  offset: 0,
									  color: 'rgba(235,157,32,0)'
									}, {
									  offset: 1,
									  color: 'rgba(235,157,32,.6)'
									}]),
								  }
								
							}
						}
					}]
			
				};
				this.chart2.setOption(option, true);
				
				this.tootipTimer2 && this.tootipTimer2.clearLoop(); 
				this.tootipTimer2 = 0;
				this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
				
			},
			
			init3() {
				this.chart3 = Echarts.init(this.$refs.chart3);
				const chartData = [
					{
						value: 998,
						name: '公路运输',
					},
					{
						value: 1245,
						name: '水路运输'
					},
					{
						value: 4432,
						name: '空运运输'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|占比最大}\n\n{b|' + sum + '}',
						top: 'center',
						textStyle: {
							 rich: {
								a: {
								   fontSize: nowSize(20),
								   padding: [0, 0, 0,nowSize(40)],
								   color: '#fff',
								   lineHeight:1,
								    fontFamily:'YouSheBiaoTiHei',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(50)],
									color: '#45F5FF',
									lineHeight:1,
									fontFamily:'YouSheBiaoTiHei'
								}
							}
						}
				   },
					tooltip: {
						trigger: 'item',
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(16),
						itemHeight: nowSize(16),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18),
							lineHeight: nowSize(30)
						},
						itemGap:  nowSize(15),
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万亩}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   padding: [0, nowSize(15), 0, 0],
								   color: '#fff',
								},
								value: {
									fontSize: nowSize(18),
									height: nowSize(20),
									padding: [0, nowSize(15), 0, 0],
									color: '#45F5FF',
								},
								percent: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#03b581', '#3478ff', '#fc9d1f', '#fe4334'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '73%'],
						center: ['20%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#031232',
							borderWidth: nowSize(2)
						},
						labelLine: {
							show: false
						},
						data:chartData
					},{
						 type: 'pie',
						 name: '内层细圆环',
						radius: ['52%', '54%'],
						center: ['20%', '48%'],
						 hoverAnimation: false,
						 clockWise: true,
						 itemStyle: {
							normal: {
							   color: '#178ac9',
							},
						 },
						 tooltip: {
							show: false,
						 },
						 label: {
							show: false,
						 },
						 data: [1],
					}]
			
				};
				this.chart3.setOption(option, true);
				
				
				
				// this.tootipTimer4 && this.tootipTimer4.clearLoop();
				// this.tootipTimer4 = 0;
				// this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
			},
			
			init4() {
				this.chart4 = Echarts.init(this.$refs.chart4);
				const chartData = [
					{
						value: 10,
						name: '蔬菜',
					},
					{
						value: 5,
						name: '豆类'
					},
					{
						value: 3,
						name: '薯类'
					},
					{
						value: 7,
						name: '其他农作物'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: '{a|产品总数}\n\n{b|' + sum + '万吨}',
						top: 'center',
						textStyle: {
							 rich: {
								a: {
								   fontSize: nowSize(20),
								   padding: [0, 0, 0,nowSize(60)],
								   color: '#fff',
								   lineHeight:1,
								    fontFamily:'PangMenZhengDao',
								},
								b: {
									fontSize: nowSize(18),
									padding: [nowSize(10), 0, 0, nowSize(70)],
									color: '#45F5FF',
									lineHeight:1,
									fontFamily:'PangMenZhengDao'
								}
							}
						}
				   },
					tooltip: {
						trigger: 'item',
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(16),
						itemHeight: nowSize(16),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(18),
							lineHeight: nowSize(30)
						},
						itemGap:  nowSize(15),
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '万吨}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   padding: [0, nowSize(15), 0, 0],
								   color: '#fff',
								},
								value: {
									fontSize: nowSize(18),
									height: nowSize(20),
									padding: [0, nowSize(15), 0, 0],
									color: '#45F5FF',
								},
								percent: {
								   fontSize: nowSize(18),
								   height: nowSize(20),
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#03b581', '#3478ff', '#fc9d1f', '#fe4334'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '70%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#031232',
							borderWidth: nowSize(2)
						},
						labelLine: {
							show: false
						},
						data:chartData
					},{
						 type: 'pie',
						 name: '内层细圆环',
						radius: ['52%', '54%'],
						center: ['25%', '48%'],
						 hoverAnimation: false,
						 clockWise: true,
						 itemStyle: {
							normal: {
							   color: '#178ac9',
							},
						 },
						 tooltip: {
							show: false,
						 },
						 label: {
							show: false,
						 },
						 data: [1],
					}]
			
				};
				this.chart4.setOption(option, true);

			},
			
			
			
			
			
				
			
				
				
		}
	}
</script>

<style scoped>
.page{background:url(../assets/images/sy_24.jpg) no-repeat center;background-size:100% 100%;}
	
.mod1{height: 100%;}
.mod1-gk{display: flex;justify-content: space-between;align-items: center;}
.mod1-gk li{display: flex;align-items: center;}
.mod1-gk .icon{width: 100px;}
.mod1-gk .info{flex:1;overflow: hidden;}
.mod1-gk .tit{font-size: 20px;color:#fff;}
.mod1-gk .num{font-size: 26px;color:#fff;font-family: 'YouSheBiaoTiHei';}

.mod1-fb{display: flex;flex-wrap: wrap;margin-bottom: 20px;}
.mod1-fb .item{width: 33.3%;padding:15px 0;display: flex;align-items: center;}
.mod1-fb .imgbox{width: 90px;height: 80px;background:url(../assets/images/sy_10.png) no-repeat center;background-size:contain;margin-right: 15px;}
.mod1-fb .icon{width: 90%;margin:0 auto;margin-top: -10px;}
.mod1-fb .info{flex:1;overflow: hidden;position:relative;padding-left: 20px;}
.mod1-fb .info::before{content:'';position:absolute;left: 0;top: 50%;width: 1px;height:60px;background-color: #304A7C;margin-top: -30px;}
.mod1-fb .tit{font-size: 26px;color:#fff;}
.mod1-fb .txt{font-size: 20px;color:#fff;}
.mod1-fb .mount{font-size: 20px;color:#fff;}
.mod1-fb .mount span{font-size: 26px;font-family: 'YouSheBiaoTiHei';padding-right: 2px;}

.mod1-chart{display: flex;justify-content: space-between;margin-bottom: 30px;max-height:20vh;}
.mod1-chart .col{width: 48%;}
.mod1-chart .chart{height:calc(100% - 40px);}

.mod1-di{display: flex;justify-content: space-between;}

.mod1-di .list{display: flex;flex-wrap: wrap;justify-content: space-between;}
.mod1-di .list .item{width: 49%;padding:10px 0;display: flex;align-items: center;}
.mod1-di .list .imgbox{width: 135px;height: 80px;border:6px solid rgb(4, 31, 72);border-radius:8px;margin-right: 10px;}
.mod1-di .list .info{flex:1;overflow: hidden;}
.mod1-di .list .title{font-size: 20px;color:#fff;}
.mod1-di .list .bot{display: flex;align-items: center;}
.mod1-di .list .txt{font-size: 22px;color:#fff;font-family: 'YouSheBiaoTiHei';}
.mod1-di .list .tag{font-size: 16px;color:rgb(0, 255, 181);box-shadow:rgb(0 255 181) 0px 0px 5px 2px inset;white-space: nowrap;border-radius:6px;padding:0 8px;line-height: 24px;font-family: 'YouSheBiaoTiHei';margin-left: 5px;}
.mod1-di .list .item:nth-child(1) .txt{color:#45F5FF;}
.mod1-di .list .item:nth-child(2) .txt{color:#FF744B;}
.mod1-di .list .item:nth-child(3) .txt{color:#14C1FD;}
.mod1-di .list .item:nth-child(4) .txt{color:#FFC55A;}
.mod1-di .scroll-list{max-height:260px;}

.mod2-bot{position:absolute;width: calc(100% + 70px);left: -35px;bottom: 0;display: flex;justify-content: space-between;}
.mod2-bot .b-smtit{margin-bottom: 10px;}
.mod2-bot .col{background-color: rgba(0,48,104,.21);border-radius:10px;height: 240px;padding:18px;}
.mod2-bot .col1{width: 32%;}
.mod2-bot .col2{width: 32%;}
.mod2-bot .col3{width: 32%;}
.mod2-bot .chart{height:calc(100% - 50px);}

.mod2-map{position:absolute;top: 17vh;bottom: 260px;width: 100%;left: 0;}
.mod2-map .item{width: 350px;padding:18px;border-radius:10px;background:rgba(0, 48, 104, 0.48);position:absolute;}
.mod2-map .item .txt{font-size: 22px;color: #45F5FF;line-height: 34px;padding-top: 7px;}
.mod2-map .item .txt p{display: flex;justify-content: space-between;margin-top: 5px;}
.mod2-map .item .tit{color:#fff;}
.mod2-map .item .txt *{font-family: 'YouSheBiaoTiHei';}

.mod2-map .item1{left: 2%;top: 15%;}
.mod2-map .item2{left: 2%;top: 50%;}
.mod2-map .item3{right: 2%;top: 50%;}

.mod3{height: 100%;}
.mod3-gk{min-height: 10vh;}
	
.mod3-sl{display: flex;justify-content: space-between;min-height: 20vh;}
.mod3-sl .col1{width: 24%;}
.mod3-sl .col2{width: 34%;}
.mod3-sl .col3{width: 38%;}
	
.mod3-sl .hang{font-size: 20px;color:#fff;line-height: 24px;padding:18px 0;}
.mod3-sl .hang span{font-size: 24px;font-family: 'YouSheBiaoTiHei';margin-left: 15px;}
.mod3-sl .hang:nth-child(1) span{color:#FFC55A;}
.mod3-sl .hang:nth-child(2) span{color:#90FFAD;}
.mod3-sl .hang:nth-child(3) span{color:#14C1FD;}

.mod3-sl .bar-list .row{margin-bottom: 15px;}

.mod3-sl .chart{height:calc(100% - 40px);}

.mod3-pm{margin-bottom: 30px;min-height: 18vh;}
.mod3-pm .list{display: flex;align-items: flex-end;margin-left: -1%;}
.mod3-pm .list .item{position:relative;width: 17%;margin-left: 1%;color:#fff;border-radius:5px;overflow: hidden;border:6px solid rgb(4, 31, 72);}
.mod3-pm .list .imgbox{height: 90px;}
.mod3-pm .list .box{position:absolute;width: 100%;height: 100%;left: 0;top: 0;display: flex;align-items: center;justify-content: center;background-color: rgba(0,0,0,.55);}
.mod3-pm .list .sz{font-size: 26px;margin-right: 10px;font-family: 'YouSheBiaoTiHei';position:relative;}
.mod3-pm .list .info{flex:1;overflow: hidden;}
.mod3-pm .list .city{font-size: 24px;font-family: 'YouSheBiaoTiHei';}
.mod3-pm .list .txt{font-size: 18px;font-family: 'YouSheBiaoTiHei';}

.mod3-pm .list .item:nth-child(1){color:#FD9D20;width:26%;}
.mod3-pm .list .item:nth-child(1) .imgbox{height: 165px;}
.mod3-pm .list .item:nth-child(1) .sz{background: linear-gradient(180deg,#FFFFFF 0%,#FFFFFF 35%,#FFC400 50%,#FFC400 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;width: 52px;font-size: 40px;margin-top: -6px;padding-left: 10px;}
.mod3-pm .list .item:nth-child(1) .sz::after{content:'';position:absolute;left:-10px;bottom: 8px;background:url(../assets/images/sy_23.png) no-repeat center;background-size:contain;width: 52px;height: 34px;}
.mod3-pm .list .item:nth-child(1) .city{font-size: 30px;}
.mod3-pm .list .item:nth-child(2){color:#fff;}
.mod3-pm .list .item:nth-child(3){color:#D28F6A;}
.mod3-pm .list .item:nth-child(4){color:#45F5FF;}
.mod3-pm .list .item:nth-child(5){color:#45F5FF;}

.mod3-di{display: flex;justify-content: space-between;}
.mod3-di .col{height: 100%;}
.mod3-di .col1{width: 50%;}
.mod3-di .col2{width: 46%;}





</style>
